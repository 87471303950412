export class ApiException {

	constructor(path, response, json) {
		if (response instanceof ApiException) {
			this.path = response.path;
			this.response = response.response;
			this.json = response.json;
			this.previous = response;
		} else {
			this.path = path;
			this.response = response;
			this.json = json;
		}
	}



	getCode() {
		return this.json.code;
	}



	getErrors() {
		return this.json.errors;
	}



	getError(message) {
		if (this.json.errors[message]) {
			return this.json.errors[message];
		}

		return this.json.errors.filter((error) => error.message === message)[0] || null;
	}

}

export class AccountSwitchAvailableException extends ApiException {}

export class InvalidInputException extends ApiException {}

export class NotFoundException extends ApiException {}

export class UnauthorizedException extends ApiException {}

export class ValidationException extends ApiException {}

export class ServerErrorException extends ApiException {}

export class TimeoutException extends ApiException {

	constructor(path, timeout) {
		super(path);

		this.timeout = timeout;
	}

}
